<template>
  <div>
    <template v-if='request.loading' >
      <v-progress-linear
        indeterminate height='2' class='ma-0 pa-0' >
      </v-progress-linear>
    </template>

    <template v-else >

      <slot v-if="request.status===200" name="message-200" ></slot>
      <slot v-if="request.status===201" name="message-201" ></slot>
      <slot v-if="request.status===204" name="message-204" ></slot>

      <slot v-if="request.status===400" name="message-400" ></slot>
      <slot v-if="request.status===401" name="message-401" ></slot>
      <slot v-if="request.status===403" name="message-403" ></slot>
      <slot v-if="request.status===404" name="message-404" ></slot>

      <slot v-if="request.status===500" name="message-500" ></slot>

      <server-errors
        v-if='request.status === 400'
        :request="request" >
      </server-errors>
      <v-alert v-else-if='request.status >= 300 && message' dense dismissible tile
        class='pa-2 ma-0'
        :type='messageType'
        :value='true' >
        {{message}}
      </v-alert>
    </template>
    <!-- <pre>{{request}}</pre> -->
    <v-snackbar v-model='showSnacker' :color='messageType' >{{message}}</v-snackbar>
  </div>
  </template>
  <script>
  import ServerErrors from './ServerErrors'
  export default {
    name: 'HomeRelationships',
    components: {ServerErrors},
    props: {
      request: { type: Object, default: () => { return {} } },
      successTimeout: { type: Number, default: 1200 },
      message200: { required: false, default: false },  // string or false (to hide the message)
      message201: { default: 'Object created' },  // string or false (to hide the message)
      message204: { type: String, default: 'Successfully deleted' },
      message404: { type: String, default: 'Resource not found' },
      message400: { type: String, default: 'There was a problem with your request. Please check your inputs and try again' },
      message401: { type: String, default: 'Permission denied' },
      message403: { type: String, default: 'Permission denied' },
      message500: { type: String, default: 'Server error. Please try again later or contact support if the problem persists' }
    },
    data () {
      return {
        showSnacker: false
      }
    },
    watch: {
      requestStatus () {
        this.$emit('status')
        let isSuccessRequestStatus = (this.requestStatus > 199 && this.requestStatus < 300)
        if (isSuccessRequestStatus) {
          this.$emit('ok', this.request)
          if (this.requestStatus === 200 && this.message200 === false) {  // explicitly ignore 200
            this.$emit('saved')
            return
          }
          if (this.requestStatus === 201 && this.message201 === false) {  // ignore 201
            this.$emit('created')
            return
          }
          if (this.requestStatus === 204 && this.message201 === false) {  // ignore 201
            this.$emit('deleted')
            return
          }
          this.showSnacker = true
        } else {
          if (this.requestStatus >= 500) {
            this.$emit('servererror')
          }
          if (this.requestStatus === 404) {
            this.$emit('notfound')
          }
          this.$emit('error', this.request)
        }
      }
    },
    computed: {
      requestStatus () {
        return this.request.status
      },
      messageType () {
        let statusMap = {
          200: 'success',
          201: 'success',
          204: 'success',
          404: 'warning',
          401: 'warning',
          403: 'warning',
          400: 'error',
          500: 'error',
          502: 'error'
        }
        return statusMap[this.request.status]
      },
      message () {
        let message = null
        let key = `message${this.request.status}`
        if (key in this) { message = this[key] }
        return message
      }
    }
  }
  </script>
