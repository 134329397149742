<template>
  <v-card>
    <request-status :request="req" />
    <!-- <img src="https://riseapp.co.za/static/images/logo.png" /> -->
    <v-card-title class="overline py-0 grey lighten-4" >
      <v-icon small class="mr-3" >mdi-lock-outline</v-icon>
      Please login
      <v-spacer/>
      <!-- <v-btn icon small ><v-icon small >mdi-replay</v-icon></v-btn> -->
    </v-card-title>
    <v-divider/>
    <v-card-text class="mt-5" >
      <v-text-field label="Username" v-model="req.data.username" />
      <v-text-field label="Password" v-model="req.data.password" type="password" />
    </v-card-text>
    <v-divider/>
    <v-card-actions class="grey lighten-4" >
      <v-spacer/>
      <v-btn @click="login" color="primary" >Login</v-btn>
    </v-card-actions>
    <!-- <v-btn @click="getMe" >get</v-btn> -->
    <!-- <pre v-text="me" class="caption pa-2 grey lighten-4" /> -->
  </v-card>
  </template>

  <script>
  import { mapActions } from 'pinia'
  import { userStore } from '@/store/user.js'
  export default {
    name: 'LoginSession',
    data () {
      return {
        req: {
          resource: 'rise.account',
          data: {
            username: '',
            password: ''
          },
          method: 'post',
          extra: 'login'
        },
        me: {
          resource: 'rise.account',
          extra: 'me'
        }
      }
    },
    watch: {
      loginStatus () {
        if (this.loginStatus === 200) {
          // window.localStorage.setItem('rise_token', this.req.result.access_token)
          this.logUserIn(this.req.result.access_token)
          this.$emit('loggedin')
        }
      }
    },
    computed: {
      user () {
        return this.me.result
      },
      loginStatus () {
        return this.req.status
      }
    },
    methods: {
      ...mapActions(userStore, ['logUserIn']),
      login () {
        this.$request('save', this.req)
      },
      getMe () {
        this.$request('get', this.me)
      }
    }
  }
  </script>
