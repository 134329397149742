<template>
  <v-alert text dismissible dense tile
    class="mt-0 mb-0 caption"
    color="error"
    v-if='request.status > 299'
    border="left" value='showAlert' >
      <v-list dense
        v-if='errorsIsIterable' >
        <v-subheader class='overline'>{{title}}</v-subheader>
        <template v-for='(errors, field) in errors' >
          <v-subheader class='overline ma-0 py-0 caption' v-if='field' :key='field' >
            {{ field.split("_").join(" ") }}
          </v-subheader>
          <v-divider :key='`div-${field}`' ></v-divider>
          <v-list-item v-for='error in [].concat(errors)' :key='error' >
            <v-list-item-content>
              <v-list-item-title v-text='error' >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <!-- <ul v-if='errorsIsIterable' >
        <li v-for='(errors, field) in errors' :key='field' >
          <strong v-if='field' >{{ field.split("_").join(" ") }}</strong>:
          <ul>
            <li v-for='error in [].concat(errors)' :key='error' >{{error}}</li>
          </ul>
        </li>
      </ul> -->
      <div v-else >{{errors}}</div>
  </v-alert>
  </template>
  <script>
  export default {
    name: 'ServerErrors',
    props: {
      request: { type: Object, required: true },
      title: { type: String, default: 'There where some errors with your request' },
      alertType: { type: String, default: 'error' }
    },
    computed: {
      errorsIsIterable () {
        if (!this.errors) { return false }
        if (typeof this.errors === 'string') { return false }
        return true
      },
      errors () {
        if (this.request && this.request.errors) {
          return this.request.errors
        }
        if (this.request && this.request.result && this.request.result.data) {
          return this.request.result.data
        }
        if (this.request && this.request.data) {
          return this.request.data
        }
        if (this.request && this.request.result) {
          return this.request.result
        }
        return null
      },
      showAlert () {
        return this.errors.length > 0
      }
    }
  }
  </script>
