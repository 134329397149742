import { defineStore } from 'pinia'
import Vue from 'vue'

export const userStore = defineStore('user', {
  state: () => ({
    isLoggedIn: false,
    loginChallenge: 0
  }),
  getters: {
    doubleCount: (state) => state.count * 2,
  },
  actions: {
    checkLoginState() {
      let sessionid = Vue.$cookies.get('sessionid')
      if (sessionid) {
        this.logUserIn()
      } else {
        this.logUserOut()
      }
    },
    logUserIn() {
      this.isLoggedIn = true
    },
    logUserOut() {
      let domain = '.' + process.env.VUE_APP_TLD
      console.log(domain)
      Vue.$cookies.remove('sessionid', null, domain)
      this.isLoggedIn = false
    },
    issueLoginChallenge() {
      this.loginChallenge++
    }
    // logUserIn() {
    //   this.isLoggedIn = true
    //   window.localStorage.setItem('token', token)
    //   this.token = token
    //   if (token) {
    //     this.isLoggedIn = true
    //   } else {
    //     window.localStorage.removeItem('token')
    //     this.isLoggedIn = false
    //   }
    // }
  },
})
