import { DateTime } from 'luxon'
import { mapActions } from 'pinia'
import { userStore } from '@/store/user.js'

export default {
  data() {
    return { defaultcurrency: 'ZAR' }
  },
  computed: {
    profilePicturePlaceholder() {
      let num = Math.floor(Math.random() * 60) + 1
      let gender = 'men'
      if (this.employee && this.employee.gender === 'F') { gender = 'women' }
      return `https://randomuser.me/api/portraits/${gender}/${num}.jpg`
    }
  },
  methods: {
    ...mapActions(userStore, ['logUserOut']),
    formatCurrency(number, currency, locale = 'en-ZA') {
      if (!currency) { currency = this.defaultcurrency }
      let formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency
      })
      return formatter.format(number)
    },
    formatnumber(val, isCurrency, currency, locale = 'en-ZA') {
      let options = {}
      if (isCurrency) {
        let defaultCurrency = this.defaultCurrency
        if (!currency) { currency = defaultCurrency }
        options = { style: 'currency', currency: currency }
      }
      return new Intl.NumberFormat(locale, options).format(val)
    },
    dateformat(isoDateString, format, locale = 'en') {
      if (!isoDateString) {
        isoDateString = DateTime.local().toISO()
      }
      let dt = DateTime.fromISO(isoDateString)
      if (format) {
        return dt.setLocale(locale).toFormat(format)
      }
      return dt.toLocaleString(DateTime.DATE_MED)
    },
    dateTimeformat(isoDatetimeString, format, locale = 'en') {
      if (!isoDatetimeString) {
        isoDatetimeString = DateTime.local().toISO()
      }
      let dt = DateTime.fromISO(isoDatetimeString)
      if (format) {
        return dt.setLocale(locale).toFormat(format)
      }
      return dt.toHTTP()
    },
    getObjectPath(object, path, defaultResponse) {
      if (object) {
        let bits = path.split('.')
        for (let key of bits) {
          try {
            if (Object.keys(object).indexOf(key) !== -1) {
              object = object[key]
            } else {
              return defaultResponse
            }
          } catch (e) {
            if (e instanceof TypeError) {
              return defaultResponse
            }
            throw e
          }
        }
        return object
      }
      return defaultResponse
    }
  }
}
