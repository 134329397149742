<template>
  <v-app class="riseapp" >
    <v-main class="ma-3" >
      <center>
      <v-card tile :dark="dark" width="500" class="mt-5 elevation-10" style="opacity:0.75;" >
        <TgAppRegistry :with-close="false" >
          <v-switch v-model="dark" slot="footer">
            <template v-slot:label>
              <v-icon v-if="dark" color="amber" >mdi-weather-sunny</v-icon>
              <v-icon v-else color="black" >mdi-weather-night</v-icon>
            </template>
          </v-switch>
        </TgAppRegistry>
      </v-card>
      </center>
    </v-main>
    <auth-session />
  </v-app>
</template>

<script>
import AuthSession from './components/ui/account/Session.vue'
import TgAppRegistry from './components/ui/TgAppRegistry.vue';
export default {
  name: 'App',
  components: {
    AuthSession,
    TgAppRegistry
  },
  data: () => ({
    dark: true
  }),
  mounted () {
    let isDark = window.matchMedia("(prefers-color-scheme:dark)").matches
    this.dark = isDark
  }
};
</script>
<style>
.riseapp {
  background-image: url('https://source.unsplash.com/random/900×700/?wallpaper') !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

}
</style>