<template>
<v-card >
  <v-card-title class='py-1 overline darken-4'>
    <v-avatar tile size="20" class="mr-2" >
      <v-img
        src="https://avatars.githubusercontent.com/u/3285854?s=200&v=4" />
    </v-avatar>
    RISE Apps
    <v-spacer />
    <v-btn icon small
      v-if="withClose"
      @click="$emit('close')" >
      <v-icon small>mdi-close</v-icon>
    </v-btn>
  </v-card-title>
  <v-divider/>
  <v-card-text>

    <template v-for="category in apps" >
      <v-row :key="category.title" >
        <v-subheader class="overline" >{{category.title}}</v-subheader>
      </v-row>
      <v-row :key="`apps-${category.title}`" >
        <v-col v-for="app in category.links"
          :key="app.title"
          cols="3"
          class="text-center" >
          <v-btn :href="app.link" icon>
            <v-icon large :color="app.color">{{app.icon}}</v-icon>
          </v-btn>
          <div class="caption">{{app.title}}</div>
        </v-col>
      </v-row>
    </template>

  </v-card-text>
  <v-divider />
  <v-footer>
    <slot name="footer" ></slot>
    <v-spacer />
    <v-btn @click="logUserOut" x-small text>Logout
      <v-icon small class="ml-1">mdi-logout</v-icon>
    </v-btn>
  </v-footer>
</v-card>
</template>

<script>
export default {
  name: 'TgAppRegistry',
  props: {
    withClose: { type: Boolean, default: true }
  },
  data () {
    return {
      'apps': [
        {
          'title': 'Rise',
          'links': [
            {
              icon: 'mdi-arrow-up-bold-hexagon-outline',
              title: 'Rise',
              link: 'http://app.' + process.env.VUE_APP_TLD,
              color: 'success'
            },
            {
              icon: 'mdi-account-tie',
              title: 'Me',
              link: 'http://my.' + process.env.VUE_APP_TLD,
              color: 'blue'
            },
            {
              icon: 'mdi-finance',
              title: 'Sales',
              link: 'http://sales.' + process.env.VUE_APP_TLD,
              color: 'warning'
            },
            {
              icon: 'mdi-open-in-app',
              title: 'Projects',
              link: 'http://projects.' + process.env.VUE_APP_TLD,
              color: 'teal'
            },
            // {
            //   icon: 'mdi-card-account-details-star-outline',
            //   title: 'Employee',
            //   link: 'http://sales.' + process.env.VUE_APP_TLD,
            //   color: 'info'
            // },
            {
              icon: 'mdi-arrow-up-bold-circle-outline',
              title: 'Rise Original',
              link: 'http://' + process.env.VUE_APP_TLD,
              color: 'warning'
            },
          ]
        },
        {
          'title': 'Tools',
          'links': [
            {
              icon: 'mdi-microsoft-azure',
              title: 'Outlook',
              link: 'http://docs.' + process.env.VUE_APP_TLD,
              color: 'blue'
            },
          ]
        },
        {
          'title': 'Developer',
          'links': [
            {
              icon: 'mdi-microsoft-azure-devops',
              title: 'Azure Devops',
              link: 'http://dev.azure.com',
              target: '_blank',
              color: 'blue'
            },
            {
              icon: 'mdi-github',
              title: 'Github',
              link: 'https://github.com/tangentSolutions/',
              target: '_blank',
              color: 'blue'
            },
            {
              icon: 'mdi-code-greater-than',
              title: 'Documentation',
              link: 'http://developer.' + process.env.VUE_APP_TLD,
              color: 'black'
            },
            {
              icon: 'mdi-codepen',
              title: 'Components',
              link: 'http://components.' + process.env.VUE_APP_TLD,
              color: 'primary'
            },
            {
              icon: 'mdi-api',
              title: 'API',
              link: 'http://api.' + process.env.VUE_APP_TLD,
              color: 'black'
            }
          ]
        }
      ]
    }
  }
}
</script>
