<template>
  <v-dialog persistent
    :overlay-opacity="80"
    v-model="loginchallenge"
    width="400" >
    <login-session @loggedin="loginchallenge=false" />
  </v-dialog>
</template>

<script>
import LoginSession from '@/components/ui/account/Login.vue'

import { mapState, mapActions } from 'pinia'
import { userStore } from '@/store/user.js'

export default {
  name: 'AuthSession',
  components: {LoginSession},
  props: {
    debug: { type: Boolean, default: false }
  },
  data: () => ({
    loginchallenge: false
  }),
  mounted () {
    this.checkLoginState(this.$cookies.get('sessionid'))
    if (window.location.host.startsWith('localhost')) {
      window.alert('It looks like youre running this on localhost. You want to be running on {appname}.docker.localhost. Refer to CSRF_TRUSTED_ORIGINS in custom_settings.py to see allowed domains')
    }
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler () {
        if (!this.isLoggedIn) {
          this.loginchallenge = true
        } else {
          this.loginchallenge = false
        }
      }
    }
  },
  computed: {
    ...mapState(userStore, ['isLoggedIn']),
    sessionid () {
      return this.$cookies.get('sessionid')
    }
  },
  methods: {
    ...mapActions(userStore, ['checkLoginState'])
  }
}
</script>
