/**

1. Provide your resources in a global config file
2. Provide a request in your data:
3. Feed that request into your desired method.

e.g.:

```
data () {
  return requests: {
    foo: {
      resource:
    }
  }
}
```
 */
import axios from 'axios'
import Vue from 'vue'
import { userStore } from '@/store/user.js'

const SERVICES = {
  riseapp: process.env.VUE_APP_RISE_ROOT
  // riseapp: 'http://api.docker.localhost'
}

export const RESOURCE = {
  'rise.account': {
    base: SERVICES.riseapp,
    path: '/account/'
  },
  'rise.projects': {
    base: SERVICES.riseapp,
    path: '/projects/'
  },
  'rise.milestones': {
    base: SERVICES.riseapp,
    path: '/milestones/'
  },
  'rise.assignments': {
    base: SERVICES.riseapp,
    path: '/assignments/'
  },
  'rise.employees': {
    base: SERVICES.riseapp,
    path: '/employees/'
  },
  'rise.positions': {
    base: SERVICES.riseapp,
    path: '/positions/'
  },
  'rise.skills': {
    base: SERVICES.riseapp,
    path: '/skills/'
  }
}

function getUrl(resource, resourceId = null, actionId = null, extra = null, kwargs = null) {
  if (!(resource in RESOURCE)) {
    console.error(resource + ' not found in RESOURCE')
    console.error(RESOURCE)
  }
  let config = RESOURCE[resource]

  if (!('base' in config)) {
    console.error('base not defined in config: ', config)
  }
  let resourceUrl = `${config.base}${config.path}`

  if (resourceId) {
    resourceUrl += resourceId + '/'
  }
  if (actionId) {
    resourceUrl += 'actions/' + actionId + '/'
  }
  if (extra) {
    resourceUrl += extra + '/'
  }
  if (kwargs) {
    Object.keys(kwargs).forEach((k) => {
      resourceUrl = resourceUrl.replace(`:${k}`, kwargs[k])
    })
  }
  return resourceUrl
}

export default {
  data() {
    return {
      $RESOURCES: RESOURCE
    }
  },
  methods: {
    requestAuthHeaders(service = null) {
      if (service) {
        console.log(service)
      }
      let headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': this.$cookies.get('csrftoken'),
        // 'sessionid': this.$cookies.get('sessionid'),
      }
      // if (store.token) {
      //   headers['authorization'] = 'Token ' + store.token
      // }
      return headers
    },
    async makeRequest(config, options) {
      try {
        // if (options && options.method === 'get' && config.loading && (!config.params || Object.keys(config.params).length === 0)) {
        //   console.log('skipping request GET ' + config.url)
        //   return null
        // }
        Vue.set(config, 'loading', true)
        Vue.set(config, 'status', '0')
        Vue.set(config, 'errors', {})

        if (options.params) {
          options.params = new URLSearchParams(options.params)
        }
        options.withCredentials = true
        let res = await axios(options)
        if (res && res.status) {
          Vue.set(config, 'result', res.data)
          Vue.set(config, 'status', res.status)
          Vue.set(config, 'loading', false)

          if (res.status > 199 && res.status < 300) {
            Vue.set(config, 'error', false)
          }
          if (res.status === 403) {
            const store = userStore()
            store.issueLoginChallenge()
          }
        }
        return res
      } catch (error) {
        Vue.set(config, 'errors', error.data)
        Vue.set(config, 'status', error.status)

        if (error.response) { // non 2xx status code
          Vue.set(config, 'errors', error.response.data)
          Vue.set(config, 'status', error.response.status)

          if (error.response.status === 401 || error.response.status === 403) {
            if (error.response.data.detail === 'Authentication credentials were not provided.') {
              const store = userStore()
              store.issueLoginChallenge()
            }
          }
        } else if (error.request) { // The request was made but no response was received
          Vue.set(config, 'errors', { message: 'Server error occured' })
          Vue.set(config, 'status', 500)
        } else {
          Vue.set(config, 'status', 500)
        }
        Vue.set(config, 'error', true)
        Vue.set(config, 'loading', false)
      }
    },
    async $request(action, config) {
      /* this.request('get', config) */
      if (action === 'get') {
        return this.__fetch(config)
      }
      if (action === 'save') {
        return this.__save(config)
      }
      if (action === 'delete') {
        return this.__delete(config)
      }
      console.error(`${action} is not a supported action. Try: 'get', 'save'`)
    },
    async __fetch(config) {
      /*
       * Perform a GET request.
       * Usage:
       * :param config: config is a request definition
       * let options = { resource: 'ag.spaces', resourceId: 9, extra: 'stream/'}
       * fetch(options) // GET /api/v3/practitioner/spaces/9/stream/
       *
       */
      let url = getUrl(config.resource, config.resourceId, null, config.extra, config.kwargs)
      let headers = this.requestAuthHeaders()
      let method = 'get'
      let params = {}
      if (config.params) {
        params = config.params
      }
      let options = { url, headers, method, params }
      return this.makeRequest(config, options)
    },
    async __delete(config) {
      let url = getUrl(config.resource, config.resourceId, null, config.extra, config.kwargs)
      let headers = this.requestAuthHeaders()
      let method = 'delete'
      let params = {}
      if (config.params) {
        params = config.params
      }
      let data = {}
      if (config.data) {
        data = config.data
      }
      let options = { url, headers, method, params, data }
      return this.makeRequest(config, options)
    },
    async __save(config) {
      let url = getUrl(config.resource, config.resourceId, null, config.extra, config.kwargs)
      let headers = this.requestAuthHeaders()
      let method = 'post'
      if (config.resourceId) {
        method = 'patch'
      }
      if (config.method) { // if explicitly specified overwrite implicit
        method = config.method
      }
      let params = {}
      if (config.params) {
        params = config.params
      }
      let data = {}
      if (config.data) {
        data = config.data
      }
      let options = { url, headers, method, params, data }
      return this.makeRequest(config, options)
    },
    // /api/v3/admin/practitioners/1/actions/convert_practitioner/
    async getAction(config) {
      return this.performAction(config, {}, 'get')
    }
    // async performAction (config, data = {}, method = 'post') {
    //   let url = getUrl(config.resource, config.resourceId, config.actionId)
    //   let headers = this.requestAuthHeaders()
    //   let options = { url, headers, method, data }
    //   return this.makeRequest(config, options)
    // }
  }
}
